<template>
    <fieldset v-if="hasLeftIcon || hasRightIcon" :class="labelClasses">
        <div v-if="hasLabel" class="label">
            <span class="label-text">
                <slot name="label" />
            </span>
        </div>
        <label
            class="flex items-center gap-2 input input-bordered"
            :class="{ 'input-error': hasError }"
        >
            <slot name="leftIcon" />
            <input
                ref="input"
                class="grow icon-input"
                v-bind="$attrs"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
            />
            <slot name="rightIcon" />
        </label>
        <div v-if="hasError" class="label">
            <output class="label-text-alt text-error">
                <slot name="error" />
            </output>
        </div>
    </fieldset>
    <label v-else class="form-control" :class="labelClasses">
        <div v-if="hasLabel" class="label">
            <span class="label-text">
                <slot name="label" />
            </span>
        </div>
        <input
            ref="input"
            class="input input-bordered"
            :class="{ 'input-error': hasError }"
            v-bind="$attrs"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <div v-if="hasError" class="label">
            <output class="label-text-alt text-error">
                <slot name="error" />
            </output>
        </div>
    </label>
</template>

<script>
import { hasSlot } from '@/helpers.js';

export default {
    name: 'TextInput',
    inheritAttrs: false,
    props: {
        labelClasses: {
            type: String,
        },
        modelValue: {
            type: String,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        hasLabel() {
            return hasSlot(this.$slots, 'label');
        },
        hasError() {
            return hasSlot(this.$slots, 'error');
        },
        hasLeftIcon() {
            return hasSlot(this.$slots, 'leftIcon');
        },
        hasRightIcon() {
            return hasSlot(this.$slots, 'rightIcon');
        },
    },
    methods: {
        focus() {
            if (!this.$refs.input) {
                return;
            }

            this.$refs.input.focus();
        },
    },
};
</script>
